<app-carousel
  label="Connaître et maîtriser vos risques"
  imgSrc="../assets/issy.jpg"
></app-carousel>
<div class="page-content">
  <app-text-section>
    <div>
      Connaître et maîtriser vos risques, c’est la meilleure façon de sécuriser
      l’atteinte de vos objectifs.
    </div>
    <div><b>STARATS</b> met son expertise au service de vos ambitions.</div>
  </app-text-section>
</div>
