import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { faLinkedin, IconDefinition } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'starats-angular';
  linkedin: IconDefinition;

  constructor(private router: Router) {
    this.linkedin = faLinkedin;
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  navigateToHome = () => {
    this.router.navigate(['/']);
  };
  navigateToAbout = () => {
    this.router.navigate(['/about']);
  };
  navigateToServices = () => {
    this.router.navigate(['/services']);
  };
  navigateToContact = () => {
    this.router.navigate(['/contact']);
  };
}
