import { Component, OnInit, Input } from '@angular/core';
import { faBars, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() logo = '';
  barsIcon: IconDefinition;

  isMenuOpen = false;

  constructor(private readonly router: Router) {
    this.barsIcon = faBars;
  }

  navigateToHome = () => {
    this.router.navigate(['/']);
  };

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  ngOnInit(): void {}
}
