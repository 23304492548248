import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { NavbarButtonComponent } from './navbar-button/navbar-button.component';
import { CarouselComponent } from './carousel/carousel.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TextSectionComponent } from './text-section/text-section.component';
import { FooterComponent } from './footer/footer.component';
import { EmailComponent } from './email/email.component';

@NgModule({
  declarations: [
    NavbarComponent,
    CarouselComponent,
    TextSectionComponent,
    FooterComponent,
    NavbarButtonComponent,
    EmailComponent,
  ],
  imports: [CommonModule, FontAwesomeModule],
  exports: [
    NavbarComponent,
    CarouselComponent,
    TextSectionComponent,
    FooterComponent,
    NavbarButtonComponent,
    EmailComponent,
  ],
})
export class ComponentsModule {}
