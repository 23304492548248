import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent {
  @Input() label = '';
  @Input() imgSrc = '';

  get style() {
    return {
      backgroundImage: `url(${this.imgSrc})`,
    };
  }
}
