<app-carousel label="À propos" imgSrc="../assets/about.jpg"></app-carousel>
<div class="page-content">
  <h1>Qui sommes-nous&nbsp;?</h1>
  <p>
    Starats est une société de conseil en management des risques, animée depuis
    2019 par Jean-Luc Meurisse, fondateur et président.
  </p>
  <h1>Jean-Luc&nbsp;:</h1>

  <div class="about__columns">
    <div class="about__left">
      <p class="about__quote">
        « J’ai <b>38 ans d’expérience de la distribution</b>, et notamment de
        1994 à 2019 chez Sonepar, leader mondial de la distribution de matériel
        électrique, dont j’ai créé et animé les fonctions consolidation, puis
        audit interne et enfin risques.
      </p>
      <p class="about__quote">
        Comme <b>directeur des risques</b>, depuis mai 2017, ma mission était
        d’assurer la gouvernance efficace et effective des risques, avec
        priorité à la conformité et à la sécurité de l'information.
      </p>
      <p class="about__quote">
        Dans le cadre du <b>programme de conformité</b> du Groupe, j’ai mené à
        bien la cartographie des risques de corruption et le déploiement de
        l’évaluation des partenaires commerciaux. »
      </p>
      <div class="about__hint">
        Le programme de conformité de Sonepar a été contrôlé par l’Agence
        Française Anticorruption (A.F.A.) à partir d’octobre 2017; à l’issue de
        ce contrôle, portant notamment sur la cartographie des risques et sur
        l’évaluation des tiers, en juillet 2019, Sonepar a été le premier groupe
        <b>certifié conforme</b> à la loi Sapin II.
      </div>

      <ul>
        <li>Certifié Risk Manager (AMRAE Formation - 2019)</li>
        <li>Diplômé de l’ESSEC (1980)</li>
      </ul>
    </div>
    <div class="about__right">
      <img src="assets/jl-meurisse-2.jpg" class="about__image" />
    </div>
  </div>
  <app-text-section>
    <div class="quote">
      “Entreprendre, au sens le plus noble, c’est prendre des risques; quand la
      prise de risques est calculée, chaque risque est aussi une opportunité”.
    </div>
  </app-text-section>
  <div class="about__columns">
    <div class="about__left">
      <img src="assets/stock-laptop.jpg" class="about__image" />
    </div>
    <div class="about__right">
      <h1>Notre expertise</h1>
      Starats vous aide à optimiser la gestion de vos risques et à en améliorer
      la gouvernance, en vous accompagnant par exemple dans vos projets
      de&nbsp;:
      <ul>
        <li>Réalisation, revue et mise à jour de cartographies des risques</li>
        <li>Conformité anticorruption et préparation à un contrôle de l'AFA</li>
        <li>Évaluation et vérification des tiers</li>
        <li>Prévention des risques de cybersécurité et de fraude</li>
      </ul>
    </div>
  </div>
</div>
