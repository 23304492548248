<app-carousel
  label="Nos services"
  imgSrc="../assets/services.jpg"
></app-carousel>
<div class="page-content">
  Avec STARATS, vous sécurisez l'atteinte de vos objectifs en acquérant la
  connaissance et la maîtrise de vos risques. Voici des exemples de missions au
  service de vos ambitions&nbsp;:
  <h3>Réalisation, revue ou mise à jour de cartographies des risques</h3>
  <ul>
    <li>
      Identification des facteurs et évènements pouvant vous empêcher
      d’atteindre vos objectifs et de réaliser votre stratégie
    </li>
    <li>
      Evaluation et classement des risques selon vos enjeux et vos priorités
    </li>
    <li>
      Analyse des dispositions réduisant les risques à un niveau acceptable, et
      mise en place de plans d’action pour en améliorer la maîtrise.
    </li>
  </ul>
  <h3>Revue du programme de conformité et préparation à un contrôle</h3>
  <ul>
    <li>
      Diagnostic portant sur la robustesse de votre dispositif anticorruption et
      le degré de préparation de vos collaborateurs à un contrôle d’une
      autorité.
    </li>
    <li>
      Utilisation de vos points forts et remédiation des faiblesses détectées.
    </li>
    <li>
      Accompagnement dans le pilotage global de vos projets de conformité&nbsp;:
      éthique, antitrust, données personnelles, devoir de vigilance, RSE, etc.
    </li>
  </ul>
  <h3>Évaluation et vérification des tiers</h3>
  <ul>
    <li>
      Conception et élaboration d’une méthodologie adaptée à votre modèle
      d’affaires, à votre profil de risques et à vos moyens
    </li>
    <li>
      Mise au point et déploiement du programme
    </li>
    <li>
      Assistance au choix des outils et à leur mise en œuvre
    </li>
  </ul>
  <h3>Prévention des risques de cybersécurité et de fraude</h3>
  <ul>
    <li>
      Réalisation ou revue de cartographies spécifiques
    </li>
    <li>
      Plan de prévention et de réduction des risques
    </li>
    <li>
      Sensibilisation des utilisateurs et maîtrise du facteur humain.
    </li>
  </ul>

  <app-text-section>
    Nous concevons chaque mission avec vous; nous pouvons former vos équipes et
    les accompagner ensuite pour un bon transfert de connaissances.
  </app-text-section>

  <span class="formation-footnote">
    STARATS est prestataire de formation enregistré sous le numéro 11922766992.
    Cet enregistrement ne vaut pas agrément de l'État.
  </span>
</div>
