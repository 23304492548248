<app-navbar logo="../assets/Logo.jpg">
  <a
    class="app-navbar__action-button"
    href="https://www.linkedin.com/company/starats-sas/"
    target="_blank"
    rel="noopener noreferrer"
    title="Retrouvez-nous sur LinkedIn"
  >
    <fa-icon [icon]="linkedin"></fa-icon>
  </a>
  <app-navbar-button
    title="ACCUEIL"
    (click)="navigateToHome()"
  ></app-navbar-button>
  <app-navbar-button
    title="À PROPOS"
    (click)="navigateToAbout()"
  ></app-navbar-button>
  <app-navbar-button
    title="NOS SERVICES"
    (click)="navigateToServices()"
  ></app-navbar-button>
  <app-navbar-button
    title="CONTACT"
    (click)="navigateToContact()"
  ></app-navbar-button>
</app-navbar>

<div class="content">
  <router-outlet></router-outlet>
</div>

<app-footer>
  <div class="app-footer__copyright">
    © STARATS - SAS
  </div>
  <a class="app-footer__legal-link" routerLink="/legal">
    Mentions légales
  </a>
</app-footer>
