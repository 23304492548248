import { Component, OnInit } from '@angular/core';
import {
  faPhone,
  faEnvelope,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  phoneIcon: IconDefinition;
  mailIcon: IconDefinition;
  linkedInIcon: IconDefinition;

  constructor() {
    this.phoneIcon = faPhone;
    this.mailIcon = faEnvelope;
    this.linkedInIcon = faLinkedin;
  }

  ngOnInit(): void {}
}
