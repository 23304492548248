import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent {
  @Input() firstPart;
  @Input() secondPart;

  get address(): string {
    return `${this.firstPart}@${this.secondPart}`;
  }
}
