<div class="page-content">
  <div class="contact__columns">
    <div class="contact__picture">
      <img src="assets/jl-meurisse-2-circle.png" class="contact__picture-img" />
    </div>
    <div class="contact__infos">
      <h2>Jean-Luc Meurisse, conseil en gestion des risques</h2>
      <div class="contact__infos-phone">
        <fa-icon [icon]="phoneIcon" class="contact__infos-icon"></fa-icon>+33 6 68 85 69 26</div>
      <div class="contact__infos-mail">
        <fa-icon [icon]="mailIcon" class="contact__infos-icon"></fa-icon>
        <app-email
          firstPart="jeanluc.meurisse"
          secondPart="starats.fr"
        ></app-email>
      </div>
      <div class="contact__infos-linkedin">
        <fa-icon [icon]="linkedInIcon" class="contact__infos-icon"></fa-icon>
        <a
          class="app-navbar__action-button"
          href="https://www.linkedin.com/company/starats-sas/"
          target="_blank"
          rel="noopener noreferrer"
          title="Retrouvez-nous sur LinkedIn"
          >/starats-sas
        </a>
      </div>
    </div>
  </div>
</div>
