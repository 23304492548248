import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LegalComponent } from './pages/legal/legal.component';
import { MainComponent } from './pages/main/main.component';
import { AboutComponent } from './pages/about/about.component';
import { ServicesComponent } from './pages/services/services.component';
import { ContactComponent } from './pages/contact/contact.component';
import { RoutingConstants } from './routing-constants';

const routes: Routes = [
  { path: RoutingConstants.home, component: MainComponent },
  { path: RoutingConstants.legal, component: LegalComponent },
  { path: RoutingConstants.about, component: AboutComponent },
  { path: RoutingConstants.services, component: ServicesComponent },
  { path: RoutingConstants.contact, component: ContactComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
